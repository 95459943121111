export default {
  items: [
    {
      name: 'Anasayfa',
      url: '/anasayfa',
      icon: 'icon-home'
    },
    {
      name: 'Çıkış',
      url: '/cikis',
      icon: 'fa fa-sign-out',
      variant: 'danger'
    }
  ]
}
