<template>
  <div class="app">
    <AppHeader fixed>
      <!-- <SidebarToggler class="d-lg-none" display="md" mobile /> -->
      <b-link class="navbar-brand" to="/">
        <img class="navbar-brand-full" src="img/logo.png" alt="www.atilimyazilim.com">
        <img class="navbar-brand-minimized" src="img/logo.png" width="30" height="30" alt="www.atilimyazilim.com">
      </b-link>
      <!-- <SidebarToggler class="d-md-down-none" display="lg" /> -->
      <b-navbar-nav class="d-md-down-none">
        <b-nav-item >{{ name }}</b-nav-item>
        <!-- <b-nav-item class="px-3" to="/firmalar" exact>Firmalar</b-nav-item> -->
        <!-- <b-nav-item class="px-3" to="/site-ayarlari">Site Ayarları</b-nav-item> -->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <div>
          <b-dropdown id="menu-dropdown" style="margin-right: 10px;" text="Menu">
            <b-dropdown-item class="bg-transparent text-black" to="/anasayfa"><i class="fa fa-home text-success"></i> Homepage</b-dropdown-item>
            <b-dropdown-item class="bg-red text-white" to="/cikis"><i class="fa fa-sign-out text-white"></i> Logout</b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- <b-nav-item class="px-2" to="/anasayfa"><button class="btn btn-md btn-default">Anasayfa</button></b-nav-item>
        <b-nav-item v-if="UrunlerSayfasiniGorebilir" class="px-2" to="/urunler"><button class="btn btn-md  btn-default"><i class="fa fa-star"></i> Ürünler</button></b-nav-item>
        <b-nav-item v-if="PersonellerSayfasiniGorebilir" class="px-2" to="/personeller"><button class="btn btn-md btn-default"><i class="fa fa-users"></i> Personeller</button></b-nav-item>
        <b-nav-item v-if="FormlarSayfasiniGorebilir" class="px-2" to="/formlar"><button class="btn btn-md btn-default"><i class="fa fa-cogs"></i> Form Ayarları</button></b-nav-item>
        <b-nav-item style="margin-right: 10px;" to="/cikis"><button class="btn btn-md btn-danger"><i class="fa fa-sign-out"></i> Çıkış</button></b-nav-item> -->
      </b-navbar-nav>
      <!-- <AsideToggler class="d-none d-lg-block" /> -->
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body">
      <!-- <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar> -->
      <main class="main">
        <!-- <Breadcrumb :list="list"/> -->
        <div style="padding: 0px;" class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside/>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="#">Aybit Lab Software</a>
        <!-- <span class="ml-1">&copy; 2005 - {{ new Date().getFullYear() }} Vitrinlik Emlak</span> -->
      </div>
      <div class="ml-auto">
        <button v-if="fullScreenSupport" class="btn btn-md btn-block btn-primary btn-full" @click="fullscreen">{{ ekranStr }}</button>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from '@/_nav'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import { headers, User, CheckAuth } from '@/shared/http';
import { forms } from '@/shared/forms';

export default {
  name: 'full',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data () {
    return {
      nav: nav.items,
      fullScreenSupport: false,
      elem: document.documentElement,
      ekranStr: 'Full Screen',
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    },
  },
  mounted() {
    if (this.elem.requestFullscreen) {
      this.fullScreenSupport = true
    } else if (this.elem.mozRequestFullScreen) { /* Firefox */
      this.fullScreenSupport = true
    } else if (this.elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      this.fullScreenSupport = true
    } else if (this.elem.msRequestFullscreen) { /* IE/Edge */
      this.fullScreenSupport = true
    }
    
  },
  methods: {
    
    fullscreen() {
      if(document.fullscreenElement) {
        document.exitFullscreen()
        this.ekranStr = 'Full Screen'
      } else {
        this.elem.requestFullscreen()
        this.ekranStr = 'Exit Full Screen'
      }
    },
  }
}
</script>

<style>
.btn-full {
  width: 50vh !important;
}
</style>
